import { Container, Grid } from "@mui/material";
import { useState } from "react";
import logo from "../images/ikdki.png";
import { ReactComponent as Logo } from "../images/ikdki.svg";
import ImageField from "./custom/ImageField";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CustomTextField from "./custom/CustomTextField";
import { isEmailValid } from "../utils/ValidationRules";
import CustomButton from "./custom/CustomButton";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");

  const linkStyle = {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#003158",
    marginLeft: "5px",
    "&:hover": {
      color: "#00213A",
    },
  };

  const sendEmailResetPassword = async (e) => {
    e.preventDefault();
    try {
      const sendEmailResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/send-mail-reset-password`,
        {
          email,
        }
      );
      Swal.fire({
        text: sendEmailResponse.data.msg,
      });
    } catch (err) {
      console.log("error : ", err);
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.msg,
        });
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f0ebeb",
        minHeight: "100vh",
        maxHeight: "100000vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="md">
        <Grid
          container
          spacing={2}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              marginTop: "5vh",
            }}
            fullwidth="true"
          >
            <Logo
              style={{
                width: "40%",
                height: "40%",
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CustomTextField
              isFullWidth={false}
              placeholder={"email@example.com"}
              icon={<AccountCircleIcon sx={{ color: "black" }} />}
              width={"90%"}
              type="email"
              value={email}
              rule={isEmailValid}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <CustomButton
              text="Send To Email"
              baseColor="#003158"
              hoverColor="#00213A"
              isFullWidth={false}
              width={"90%"}
              onClick={sendEmailResetPassword}
            />
          </Grid>
          <Grid
            style={{
              marginTop: "10px",
            }}
          >
            <Link to="/" style={linkStyle}>
              kembali ke halaman login
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ResetPassword;
