import React, { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Grid,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ImageField from "./custom/ImageField";
import logo from "../images/ikdki.png";
import { ReactComponent as Logo } from "../images/ikdki.svg";
import { useUser } from "../pages/UserContext";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BiotechIcon from "@mui/icons-material/Biotech";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SchoolIcon from "@mui/icons-material/School";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EventIcon from "@mui/icons-material/Event";
import Equalizer from "@mui/icons-material/Equalizer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Sidebar = () => {
  const userDetails = useUser();
  const role = userDetails?.role;
  const location = useLocation();
  const [open, setOpen] = useState({});

  const handleToggle = (text) => {
    setOpen((prevOpen) => ({ ...prevOpen, [text]: !prevOpen[text] }));
  };

  const menuItems = [
    {
      text: "Profil",
      path: "/dashboard",
      roles: ["admin"],
      icon: <PersonIcon />,
    },
    {
      text: "Profile",
      path: "/user-profile",
      roles: [
        "Mahasiswa/i",
        "Dosen",
        "Tenaga Pendidik / Tendik",
        "Anggota Kehormatan",
        "Mahasiswa/i",
      ],
      icon: <PersonIcon />,
    },
    {
      text: "Data Diri",
      path: "/update",
      roles: [
        "Mahasiswa/i",
        "Dosen",
        "Tenaga Pendidik / Tendik",
        "Anggota Kehormatan",
        "Mahasiswa/i",
      ],
      icon: <EditIcon />,
    },
    {
      text: "Calon Anggota",
      path: "/calon-anggota",
      roles: ["admin"],
      icon: <PersonAddIcon />,
    },
    {
      text: "Anggota IKDKI",
      path: "/anggota",
      roles: ["admin"],
      icon: <PeopleAltIcon />,
    },
    {
      text: "Institusi",
      path: "/institusi",
      roles: ["admin"],
      icon: <LocationCityIcon />,
    },
    {
      text: "Reporting",
      path: "/reporting",
      roles: ["admin"],
      icon: <Equalizer />,
    },
    {
      text: "Konfirmasi Edit User",
      path: "/edit-user",
      roles: ["admin"],
      dynamicPath: "/update-confirmation",
      icon: <ManageAccountsIcon />,
    },
    {
      text: "Tabel Pengabdian",
      path: "/tabel-pengabdian",
      roles: [
        "admin",
        "Dosen",
        "Tenaga Pendidik / Tendik",
        "Anggota Kehormatan",
        "Mahasiswa/i",
      ],
      icon: <SchoolIcon />,
    },
    {
      text: "Tabel Penelitian",
      path: "/tabel-penelitian",
      roles: [
        "admin",
        "Dosen",
        "Tenaga Pendidik / Tendik",
        "Anggota Kehormatan",
        "Mahasiswa/i",
      ],
      icon: <BiotechIcon />,
    },
    {
      text: "Tabel Publikasi",
      path: "/tabel-publikasi",
      roles: [
        "admin",
        "Dosen",
        "Tenaga Pendidik / Tendik",
        "Anggota Kehormatan",
        "Mahasiswa/i",
      ],
      icon: <AssignmentIcon />,
    },
    {
      text: "Tabel Pengajaran",
      path: "/tabel-pengajaran",
      roles: [
        "admin",
        "Dosen",
        "Tenaga Pendidik / Tendik",
        "Anggota Kehormatan",
        "Mahasiswa/i",
      ],
      icon: <LocalLibraryIcon />,
    },
    // {
    //   text: "Acara Tambahan",
    //   roles: ["admin"],
    //   icon: <SchoolIcon />,
    //   children: [
    //     {
    //       text: "Pendaftaran Rakernas",
    //       path: "/tabel-pengajaran",
    //       roles: ["admin", "Dosen", "Tenaga Pendidik / Tendik", "Mahasiswa/i"],
    //     },
    //   ],
    // },
  ];

  const isActive = (itemPath, dynamicPath) => {
    return (
      location.pathname === itemPath ||
      location.pathname.startsWith(dynamicPath || itemPath)
    );
  };

  return (
    <div>
      <Grid
        item
        md={12}
        xs={12}
        fullwidth
        sx={{
          textAlign: "center",
        }}
      >
        <Logo
          style={{
            width: "40%",
            height: "40%",
            marginTop: "2vh"
          }}
        />
        {/* <ImageField src={logo} width={"40%"} height={"40%"} /> */}
      </Grid>
      <List sx={{ width: "100%", height: "100%", marginTop: "0" }}>
        {menuItems.map(
          (item) =>
            userDetails &&
            item.roles.includes(role) && (
              <div key={item.text}>
                {item.children ? (
                  <>
                    <ListItemButton
                      onClick={() => handleToggle(item.text)}
                      sx={{
                        justifyContent: "center",
                        backgroundColor: isActive(item.path, item.dynamicPath)
                          ? "#003158"
                          : "white",
                        "&:hover": {
                          backgroundColor: "#003158",
                          "& .MuiListItemText-primary": {
                            color: "#fff",
                          },
                          "& .MuiListItemIcon-root": {
                            color: "#fff",
                          },
                        },
                        "& .MuiListItemText-primary": {
                          color: isActive(item.path, item.dynamicPath)
                            ? "#fff"
                            : "#003158",
                          textAlign: "left",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: isActive(item.path, item.dynamicPath)
                            ? "#fff"
                            : "#003158",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                      {open[item.text] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.children.map(
                          (child) =>
                            userDetails &&
                            child.roles.includes(role) && (
                              <ListItemButton
                                key={child.text}
                                component={NavLink}
                                to={child.path}
                                sx={{
                                  pl: 4,
                                  justifyContent: "center",
                                  backgroundColor: isActive(
                                    child.path,
                                    child.dynamicPath
                                  )
                                    ? "#003158"
                                    : "white",
                                  "&:hover": {
                                    backgroundColor: "#003158",
                                    "& .MuiListItemText-primary": {
                                      color: "#fff",
                                    },
                                    "& .MuiListItemIcon-root": {
                                      color: "#fff",
                                    },
                                  },
                                  "& .MuiListItemText-primary": {
                                    color: isActive(
                                      child.path,
                                      child.dynamicPath
                                    )
                                      ? "#fff"
                                      : "#003158",
                                    textAlign: "left",
                                  },
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    color: isActive(
                                      child.path,
                                      child.dynamicPath
                                    )
                                      ? "#fff"
                                      : "#003158",
                                  }}
                                >
                                  {child.icon}
                                </ListItemIcon>
                                <ListItemText primary={child.text} />
                              </ListItemButton>
                            )
                        )}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ListItemButton
                    key={item.text}
                    component={NavLink}
                    to={item.path}
                    sx={{
                      justifyContent: "center",
                      backgroundColor: isActive(item.path, item.dynamicPath)
                        ? "#003158"
                        : "white",
                      "&:hover": {
                        backgroundColor: "#003158",
                        "& .MuiListItemText-primary": {
                          color: "#fff",
                        },
                        "& .MuiListItemIcon-root": {
                          color: "#fff",
                        },
                      },
                      "& .MuiListItemText-primary": {
                        color: isActive(item.path, item.dynamicPath)
                          ? "#fff"
                          : "#003158",
                        textAlign: "left",
                      },
                    }}
                  >
                    {item.icon && (
                      <ListItemIcon
                        sx={{
                          color: isActive(item.path, item.dynamicPath)
                            ? "#fff"
                            : "#003158",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={item.text}
                      sx={{ textAlign: "left" }}
                    />
                  </ListItemButton>
                )}
              </div>
            )
        )}
      </List>
    </div>
  );
};

export default Sidebar;
