import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Swal from "sweetalert2";
import backCard from "../../images/back_card.png";
import frontCard from "../../images/front_card.png";
import logo from "../../images/ikdki.png";
import PrintIcon from "@mui/icons-material/Print";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import CustomIconButton from "../custom/CustomIconButton";

export default function MemberData() {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalLecturer, setTotalLecturer] = useState(0);
  const [totalAnggotaKehormatan, setTotalAnggotaKehormatan] = useState(0);
  const [totalTendik, setTotalTendik] = useState(0);
  const [totalMahasiswa, setTotalMahasiswa] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  const handleViewChanges = (userId) => {
    navigate(`/anggota/${userId}`);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/users?member=1`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        setRows(
          response.data.data.map((user, index) => ({
            userId: user.uuid,
            cardNum: user.card_num,
            yearJoin: user.year_join,
            id: index + 1 + (page - 1) * 10,
            namaAnggota: user.nama_lengkap,
            universitas: user.institusi_asal,
            noAnggota: user.id_users,
            provinsi: user.provinsi_institusi,
            role: user.role,
            idUsers: user.id_users,
            expiredCardAt: user.expired_card_at,
            keahlian: `${user.keahlian_1}, ${user.keahlian_2}, ${user.keahlian_3}`,
          }))
        );
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error("Error fetching member", error);
      });

    const fetchLecturer = async () => {
      const jwtToken = localStorage.getItem("token");
      try {
        if (jwtToken) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/role`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
              params: {
                role: "Dosen",
              },
            }
          );
          setTotalLecturer(response.data.data);
        } else {
          console.error("JWT token is not available");
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };
    const fetchAnggotaKehormatan = async () => {
      const jwtToken = localStorage.getItem("token");
      try {
        if (jwtToken) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/role`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
              params: {
                role: "Anggota Kehormatan",
              },
            }
          );
          setTotalAnggotaKehormatan(response.data.data);
        } else {
          console.error("JWT token is not available");
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };
    const fetchTendik = async () => {
      const jwtToken = localStorage.getItem("token");
      try {
        if (jwtToken) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/role`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
              params: {
                role: "Tenaga Pendidik / Tendik",
              },
            }
          );
          setTotalTendik(response.data.data);
        } else {
          console.error("JWT token is not available");
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };
    const fetchMahasiswa = async () => {
      const jwtToken = localStorage.getItem("token");
      try {
        if (jwtToken) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/role`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
              params: {
                role: "Mahasiswa/i",
              },
            }
          );
          setTotalMahasiswa(response.data.data);
        } else {
          console.error("JWT token is not available");
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };
    fetchLecturer();
    fetchMahasiswa();
    fetchTendik();
    fetchAnggotaKehormatan();
  }, []);

  const generateHTML = (divContents) => {
    return `
      <html>
        <head>
          <style>
            #logo {
              left: 42px !important;
            }
            #cardMemberName {
              left: 5px !important;
              top: 262px !important;
              color: white !important;
              // top: 200px !important;
            }
            #cardMemberNumber {
              left: 5px !important;
              top: 285px !important;
              color: white !important;
            }
            #cardYearExpired {
              left: 210px !important;
              top: 78px !important;
              color: white !important;
            }
          </style>
        </head>
        <body width='100%' style="text-align">
          ${divContents}
        </body>
      </html>
    `;
  };

  const downloadCanvas = async (canvasId) => {
    var divContents = document.getElementById(canvasId).innerHTML;
    var a = window.open("", "", "height=500, width=500");
    a.document.write(generateHTML(divContents));
    a.document.close();
    setTimeout(() => {
      a.print();
    }, 4500);
  };

  const printCard = async (namaAnggota, nomorAnggota, tahunPertamaMenjadiMember, tahunKadaluarsaKartu) => {
    Swal.fire({
      html: `
        <div style="position: relative;" id="cardCanvas">
          <div id="cardMemberName" 
              style="position: absolute; top: 258px; left: 25px; color: white; font-size: 10px; text-align: center; width: 200px; 
                      word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">
            <b>${namaAnggota}</b>
          </div>
          <div id="cardMemberNumber" style="position: absolute; top: 275px; left: 25px; color: white; font-size: 12px; text-align: center; width: 200px;"><b>${nomorAnggota}</b></div>
          <div id="cardYearExpired" style="position: absolute; top: 72px; left: 227px; color: white; font-size: 13px; text-align: center; width: 200px;">
            <b>01 Jan ${tahunPertamaMenjadiMember} s/d ${tahunKadaluarsaKartu}</b>
          </div>
          <img id="frontCardImage" src="${frontCard}" alt="Front Card" style="width: 200px; height: auto;">
          <img id="logo" src="${logo}" alt="Logo" style="position: absolute; top: 120px; left: 58px; width: 130px; height: auto;">
          <img id="backCardImage" src="${backCard}" alt="Back Card" style="width: 200px; height: auto;">
          <br>
        </div>
      `,
      showCloseButton: true,
      confirmButtonText: "Print",
    }).then((res) => {
      if (res.isConfirmed === true) {
        downloadCanvas("cardCanvas");
      }
    });
  };

  const renewMembership = async (uuid) => {
    Swal.fire({
      icon: "warning",
      text: `Perbaharui masa keanggotaan ?`,
      showCancelButton: true,
      confirmButtonText: "Yes, perbaharui",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const jwtToken = localStorage.getItem("token");
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_HOST}/users/member/renew-membership`,
            { uuid: uuid },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          Swal.fire({
            icon: "success",
            text: response.data.msg,
          });
          setRefresh(!refresh);
        } catch (error) {
          Swal.fire({
            icon: "error",
            text: error.response.data.msg,
          });
        }
      }
    });
  };

  const pageSizeOptions = [25, 50, 100, { label: "All", value: rows.length }];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      Jumlah Dosen : {totalLecturer} <br></br>
      Jumlah Anggota Kehormatan : {totalAnggotaKehormatan} <br></br>
      Jumlah Tenaga Pendidik / Tendik : {totalTendik} <br></br>
      Jumlah Mahasiswa/i : {totalMahasiswa} <br></br> <br></br>
      <DataGrid
        sx={{
          textAlign: "center",
        }}
        rows={rows}
        columns={[
          { field: "id", headerName: "No.", width: "100" },
          { field: "namaAnggota", headerName: "Nama Anggota", flex: 1 },
          { field: "universitas", headerName: "Universitas", flex: 1 },
          { field: "provinsi", headerName: "Provinsi", flex: 1 },
          { field: "noAnggota", headerName: "No. Anggota", width: "100" },
          { field: "role", headerName: "Role", flex: 1 },
          { field: "keahlian", headerName: "Keahlian", flex: 1 },
          {
            field: "action",
            headerName: "Action",
            headerAlign: "justify",
            width: "250",
            renderCell: (cellValues) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CustomIconButton
                  isFullWidth={false}
                  width={"10px"}
                  variant="contained"
                  icon={<EditIcon sx={{ color: "WHITE" }} />}
                  baseColor="#003158"
                  hoverColor="#00213A"
                  tooltipText="Edit User"
                  onClick={() => handleViewChanges(cellValues.row.userId)}
                />
                <CustomIconButton
                  isFullWidth={false}
                  width={"10px"}
                  variant="contained"
                  icon={<PrintIcon sx={{ color: "WHITE" }} />}
                  baseColor="#ed8932"
                  hoverColor="#c96c1a"
                  tooltipText="Cetak Kartu Keanggotaan"
                  onClick={() =>
                    printCard(
                      cellValues.row.namaAnggota,
                      cellValues.row.cardNum,
                      cellValues.row.yearJoin,
                      cellValues.row.expiredCardAt
                    )
                  }
                />
                <CustomIconButton
                  isFullWidth={true}
                  variant="contained"
                  icon={<AutorenewIcon sx={{ color: "WHITE" }} />}
                  baseColor="#A92027"
                  hoverColor="#8C1016"
                  tooltipText="Perbaharui Masa Keanggotaan"
                  onClick={() => renewMembership(cellValues.row.userId)}
                />
              </div>
            ),
          },
        ]}
        pagination
        onPageChange={(newPage) => setPage(newPage)}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
